import { Color, PaletteColorOptions, PaletteOptions, TypeText } from '@mui/material';
import { common } from '@mui/material/colors';

import { getVariableOrDefaultValue } from '../utils/cssVariable';

const defaultColors = {
  primaryMain: '#f2755f',
  primaryLight: '#fef4f2',
  primaryDark: '#b43721',
  primaryContrastText: '#ffffff',

  secondaryMain: '#6c757d',
  secondaryLight: '#f5f6f6',
  secondaryDark: '#3a3c3f',
  secondaryContrastText: '#ffffff',

  successMain: '#198754',
  successLight: '#f0fdf6',
  successDark: '#155237',
  successContrastText: '#ffffff',

  infoMain: '#2196f3',
  infoLight: '#eefaff',
  infoDark: '#1a548e',
  infoContrastText: '#ffffff',

  warningMain: '#ffb020',
  warningLight: '#fff9eb',
  warningDark: '#dd6702',
  warningContrastText: '#ffffff',

  errorMain: '#f44336',
  errorLight: '#fef3f2',
  errorDark: '#be2217',
  errorContrastText: '#ffffff',

  textPrimary: '#121828',
  textSecondary: '#65748b',
  textDisabled: '#3741517a',

  grey50: '#fafafa',
  grey100: '#f5f5f5',
  grey200: '#eeeeee',
  grey300: '#e0e0e0',
  grey400: '#bdbdbd',
  grey500: '#9e9e9e',
  grey600: '#757575',
  grey700: '#616161',
  grey800: '#424242',
  grey900: '#212121',
  greyA100: '#f5f5f5',
  greyA200: '#eeeeee',
  greyA400: '#bdbdbd',
  greyA700: '#616161',
};

const primary: PaletteColorOptions = {
  main: getVariableOrDefaultValue('--primary-main', defaultColors.primaryMain),
  light: getVariableOrDefaultValue(
    '--primary-light',
    defaultColors.primaryLight
  ),
  dark: getVariableOrDefaultValue('--primary-dark', defaultColors.primaryDark),
  contrastText: getVariableOrDefaultValue(
    '--primary-contrast-text',
    defaultColors.primaryContrastText
  ),
};

const secondary: PaletteColorOptions = {
  main: getVariableOrDefaultValue(
    '--secondary-main',
    defaultColors.secondaryMain
  ),
  light: getVariableOrDefaultValue(
    '--secondary-light',
    defaultColors.secondaryLight
  ),
  dark: getVariableOrDefaultValue(
    '--secondary-dark',
    defaultColors.secondaryDark
  ),
  contrastText: getVariableOrDefaultValue(
    '--secondary-contrast-text',
    defaultColors.secondaryContrastText
  ),
};

const success: PaletteColorOptions = {
  main: getVariableOrDefaultValue('--success-main', defaultColors.successMain),
  light: getVariableOrDefaultValue(
    '--success-light',
    defaultColors.successLight
  ),
  dark: getVariableOrDefaultValue('--success-dark', defaultColors.successDark),
  contrastText: getVariableOrDefaultValue(
    '--success-contrast-text',
    defaultColors.successContrastText
  ),
};

const info: PaletteColorOptions = {
  main: getVariableOrDefaultValue('--info-main', defaultColors.infoMain),
  light: getVariableOrDefaultValue('--info-light', defaultColors.infoLight),
  dark: getVariableOrDefaultValue('--info-dark', defaultColors.infoDark),
  contrastText: getVariableOrDefaultValue(
    '--info-contrast-text',
    defaultColors.infoContrastText
  ),
};

const warning: PaletteColorOptions = {
  main: getVariableOrDefaultValue('--warning-main', defaultColors.warningMain),
  light: getVariableOrDefaultValue(
    '--warning-light',
    defaultColors.warningLight
  ),
  dark: getVariableOrDefaultValue('--warning-dark', defaultColors.warningDark),
  contrastText: getVariableOrDefaultValue(
    '--warning-contrast-text',
    defaultColors.warningContrastText
  ),
};

const error: PaletteColorOptions = {
  main: getVariableOrDefaultValue('--error-main', defaultColors.errorMain),
  light: getVariableOrDefaultValue('--error-light', defaultColors.errorLight),
  dark: getVariableOrDefaultValue('--error-dark', defaultColors.errorDark),
  contrastText: getVariableOrDefaultValue(
    '--error-contrast-text',
    defaultColors.errorContrastText
  ),
};

const text: TypeText = {
  primary: getVariableOrDefaultValue(
    '--text-primary',
    defaultColors.textPrimary
  ),
  secondary: getVariableOrDefaultValue(
    '--text-secondary',
    defaultColors.textSecondary
  ),
  disabled: getVariableOrDefaultValue(
    '--text-disabled',
    defaultColors.textDisabled
  ),
};

const grey: Partial<Color> = {
  50: getVariableOrDefaultValue('--grey50', defaultColors.grey50),
  100: getVariableOrDefaultValue('--grey100', defaultColors.grey100),
  200: getVariableOrDefaultValue('--grey200', defaultColors.grey200),
  300: getVariableOrDefaultValue('--grey300', defaultColors.grey300),
  400: getVariableOrDefaultValue('--grey400', defaultColors.grey400),
  500: getVariableOrDefaultValue('--grey500', defaultColors.grey500),
  600: getVariableOrDefaultValue('--grey600', defaultColors.grey600),
  700: getVariableOrDefaultValue('--grey700', defaultColors.grey700),
  800: getVariableOrDefaultValue('--grey800', defaultColors.grey800),
  900: getVariableOrDefaultValue('--grey900', defaultColors.grey900),
  A100: getVariableOrDefaultValue('--grey-a100', defaultColors.greyA100),
  A200: getVariableOrDefaultValue('--grey-a200', defaultColors.greyA200),
  A400: getVariableOrDefaultValue('--grey-a400', defaultColors.greyA400),
  A700: getVariableOrDefaultValue('--grey-a700', defaultColors.greyA700),
};

const palette: PaletteOptions = {
  primary,
  secondary,
  success,
  info,
  warning,
  error,
  text,
  grey,
  divider: grey[300],
  background: {
    default: common.white,
    paper: common.white,
  },
};

console.log(palette, 'Here is the palette');

export default palette;
