import { Components, Theme } from '@mui/material';

import palette from './palette';
import shadows from './shadows';

const components: Components<Omit<Theme, 'components'>> = {
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      variant: 'contained',
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
      sizeSmall: {
        padding: '.25rem .5rem',
        borderRadius: '.35rem',
      },
      sizeMedium: {
        padding: '.5rem 1rem',
      },
      sizeLarge: {
        padding: '.5rem 1rem',
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: {
      html: {
        MozOsxFontSmoothing: 'grayscale',
        WebkitFontSmoothing: 'antialiased',
      },
      'body::-webkit-scrollbar': {
        width: '8px',
      },

      'body::-webkit-scrollbar-thumb': {
        borderRadius: '0.5rem',
        backgroundColor: palette.grey?.[200],
      },
      'body:hover': {
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: palette.grey?.[300],
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        borderColor: palette.grey?.[300],
      },
    },
    variants: [
      {
        props: {
          type: 'color',
        },
        style: {
          border: 'none',
          cursor: 'pointer',
          '.MuiInputBase-input': {
            padding: 0,
            height: '2rem',
            width: '4rem',
          },
          '.MuiFormLabel-root': {
            transform: 'none',
          },
        },
      },
    ],
  },
  MuiCard: {
    variants: [
      {
        props: { variant: 'elevation' },
        style: {
          boxShadow: shadows[1],
        },
      },
    ],
  },

  MuiCardContent: {
    styleOverrides: {
      root: {
        paddingBottom: '1rem !important',
      },
    },
  },
  MuiPaper: {
    defaultProps: {
      sx: {
        borderRadius: '.5rem',
      },
    },
  },
  MuiDialog: {
    variants: [
      {
        props: { maxWidth: 'md' },
        style: {
          maxWidth: `700px`,
          marginInline: 'auto',
        },
      },
    ],
    defaultProps: {
      BackdropProps: {
        sx: {
          backdropFilter: 'blur(2px)',
          // backgroundColor: 'rgb(255 255 255 / 0%)',
        },
      },
    },
  },
  // MuiDialogContent: {
  //   styleOverrides: {
  //     root: {
  //       padding: '1rem',
  //       overflowX: 'auto',

  //     },
  //   },
  // },

  MuiFormControl: {
    defaultProps: {
      variant: 'outlined',
      size: 'medium',
    },
    variants: [
      {
        props: { variant: 'outlined', size: 'medium' },
        style: {
          '.MuiInputBase-input': {
            paddingBlock: '.7rem',
          },
          '.MuiFormLabel-root': {
            transform: 'translate(14px, 11px) scale(1)',
            '&.Mui-focused, &.MuiInputLabel-shrink': {
              transform: 'translate(14px, -9px) scale(.75)',
            },
          },
        },
      },
    ],
  },

  MuiTableHead: {
    defaultProps: {
      sx(theme) {
        return {
          backgroundColor: theme.palette.grey[100],
          '.MuiTableCell-head': {
            borderBottom: 'none',
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.grey[100],
          },
        };
      },
    },
  },

  MuiTable: {
    defaultProps: {
      sx(theme) {
        return {
          borderRadius: `.75rem`,
        };
      },
    },
  },

  MuiTableCell: {
    defaultProps: {
      sx(theme) {
        return {
          borderBottom: `1px solid ${theme.palette.grey[200]}`,
        };
      },
    },
  },
  MuiTableRow: {
    defaultProps: {
      sx() {
        return {
          '&:last-child .MuiTableCell-body': {
            borderBottom: 'none',
          },
        };
      },
    },
  },
  MuiAutocomplete: {
    variants: [
      {
        props: {
          size: 'medium',
        },
        style: {
          // display:'none'
        },
      },
    ],
  },
  MuiMenu: {
    styleOverrides: {
      list: {
        maxHeight: 250,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: 'capitalize',
      },
    },
  },
  MuiToggleButtonGroup: {
    defaultProps: {
      color: 'primary',
    },
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.primary.light,
        '& .MuiToggleButtonGroup-grouped': {
          border: 0,
          borderRadius: theme.shape.borderRadius,
          '&.Mui-disabled': {
            border: 0,
          },
          color: theme.palette.primary.main,
          '&:not(:first-of-type)': {
            borderLeft: 0,
            borderRadius: theme.shape.borderRadius,
          },
          '&:first-of-type': {
            borderRight: 0,
            borderRadius: theme.shape.borderRadius,
          },
        },
      }),
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
    variants: [
      {
        props: {
          selected: true,
          color: 'primary',
        },
        style: ({ theme }) => ({
          backgroundColor: `${theme.palette.primary.main} !important`,
          color: `${theme.palette.common.white} !important`,
        }),
      },
    ],
  },
  MuiSkeleton: {
    styleOverrides: {
      root: {
        transform: 'none',
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: ({ theme }) => ({
        width: 40,
        height: 22,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(18px)',
            color: theme.palette.background.paper,
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.primary.main,
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.grey[100],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 18,
          height: 18,
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: theme.palette.grey[300],
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      }),
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        backgroundColor: theme.palette[ownerState.severity || 'info'].light
      })
    }
  }
};

export default components;
