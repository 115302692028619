import "../styles/colors.scss";
import { Palette, Theme } from '@mui/material';
import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useState,
} from 'react';

import AppTheme from '../interfaces/AppTheme';
import defaultTheme from '../theme';

const AppThemeContext = createContext<AppTheme>({} as any);

export const AppThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [theme, setTheme] = useState<Theme>(defaultTheme);

  const setPalette = useCallback((palette: Partial<Palette>) => {
    setTheme((theme) => ({
      ...theme,
      palette: { ...theme.palette, ...palette },
    }));
  }, []);

  return (
    <AppThemeContext.Provider value={{ ...theme, setPalette }}>
      {children}
    </AppThemeContext.Provider>
  );
};

export default AppThemeContext;
