const getVariableValue = (variable: string): string => {
  return getComputedStyle(document.documentElement)
    .getPropertyValue(variable)
    .trim();
};

const getVariableOrDefaultValue = (
  variable: string,
  defaultValue: string
): string => {
  return getVariableValue(variable) || defaultValue;
};

const updateVariable = (name: string, value: string) => {
  document.documentElement.style.setProperty(`--${name}`, value);
};

export { getVariableValue, getVariableOrDefaultValue, updateVariable };
